<template>
  <div>
    <c-search-box @enter="getEquipClassList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사용여부 -->
          <c-select
            :comboItems="hazardMachineItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="hazardMachineFlag"
            label="유해위험기계기구 여부"
            v-model="searchParam.hazardMachineFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-tree-table
          title="설비유형 목록"
          parentProperty="upEquipmentTypeCd"
          customID="equipmentTypeCd"
          :columns="gridClass.columns"
          :data="gridClass.data"
          :columnSetting="false"
          :gridHeight="gridClass.height"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getEquipClassList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="itemTable"
          title="설비유형별 점검항목 목록"
          :columns="insItemgrid.columns"
          :data="insItemgrid.data"
          selection="multiple"
          :usePaging="false"
          :expandAll="true"
          gridHeight="350px"
          rowKey="checkItemNo"
          @linkClick="rowClassClick"
        >
          <template slot="table-chip">
            <q-btn-group outline >
              <q-chip outline square>
                  <q-avatar icon="push_pin" color="green" text-color="white" /> 
                  {{ "설비유형 : " + rowTypeName}}
              </q-chip>
            </q-btn-group>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && updateMode" label="추가" icon="add" :showLoading="false" @btnClicked="addrow" />
              <c-btn
                v-if="editable && updateMode"
                :url="insertUrl"
                :isSubmit="isSave"
                :param="insItemgrid.data"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="saveClass"
                @btnCallback="saveCallback" />
              <c-btn v-if="editable && updateMode && insItemgrid.data.length > 0" :showLoading="false" label="삭제" icon="remove" @btnClicked="remove"/>
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'click'">
              <q-chip outline square
                color="orange"
                :clickable="true"
                :editable="editable"
                text-color="white"
                @click="rowClassClick(props.row, col.name)">
                {{"CLICK"}}
              </q-chip>
            </template>
          </template>
        </c-table> 
        <c-table
          ref="itemTable2"
          title="점검항목별 상세"
          :columns="insItemgridDetail.columns"
          :data="insItemgridDetail.data"
          selection="multiple"
          :editable="updateMode2"
          :usePaging="false"
          :expandAll="true"
          gridHeight="350px"
          rowKey="checkMethodCd"
        >
          <template slot="table-chip">
            <q-btn-group outline >
              <q-chip outline square>
                  <q-avatar icon="push_pin" color="green" text-color="white" /> 
                  {{ "점검항목 : " + rowCheckItemName}}
              </q-chip>
            </q-btn-group>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="updateMode2" label="추가" icon="add" :showLoading="false" @btnClicked="addrow2" />
              <c-btn
                  v-if="editable && updateMode2"
                  :url="infoInsertUrl"
                  :isSubmit="isSave2"
                  :param="insItemgridDetail.data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveClass2"
                  @btnCallback="saveCallback2" />
              <c-btn v-if="editable && updateMode2 && insItemgridDetail.data.length > 0" :showLoading="false" label="삭제" icon="remove" @btnClicked="remove2"/>
            </q-btn-group>
          </template>
        </c-table> 
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default 
  {
  name: 'equipment-class-ins-item',
  data() {
    return {
      gridClass: {
        columns: [
          {
            name: 'equipmentTypeNm',
            field: 'equipmentTypeNm',
            label: '설비유형',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'hazardMachineFlagNm',
            field: 'hazardMachineFlagNm',
            label: '유해위험기계기구 여부',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          // {
          //   name: 'useFlagNm',
          //   field: 'useFlagNm',
          //   label: '사용여부',
          //   align: 'center',
          //   style: 'width:80px',
          //   sortable: false,
          // },
        ],
        data: [],
        height: '720px'
      },
      insItemgrid: {
        columns: [
          {
            name: 'equipmentCheckTypeCd',
            field: 'equipmentCheckTypeCd',
            label: '설비점검종류',
            required: true,
            align: 'center',
            style: 'width:100px',
            type: 'select',
            comboItems: [
              { code: 'MCKC000005', codeName: '자체(일상)정비' },
              { code: 'MCKC000010', codeName: '법정(정기)정비' },
            ],
            sortable: false,
          },
          {
            name: 'checkItemNm',
            field: 'checkItemNm',
            label: '점검항목 내용',
            style: 'width:300px',
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            name: 'click',
            field: 'click',
            label: '',
            align: 'center',
            style: 'width:50px',
            type: "custom",
            sortable: false,
          },
        ],
        height: '120px',
        data: [],
      },
      insItemgridDetail: {
        columns: [],
        height: '120px',
        data: [],
      },
      searchParam: {
        plantCd: null,
        hazardMachineFlag: null,
        useFlag: 'Y',
      },
      equipClassInsItemData: {
        equipmentType: '',
        plantCd: '',
        equipmentCheckTypeCd: null,
        checkItemNo: '',
        checkItemNm: '',
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
      },
      editable: true,
      listUrl: '',
      itemDetailUrl: '',
      deleteUrl: '',
      insertUrl: '',
      updateUrl: '',
      checkUrl: '',
      infoInsertUrl: '',
      infoUpdateUrl: '',
      infoDeleteUrl: '',
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      hazardMachineItems: [
        { code: 'Y', codeName: '유해위험기계기구' },
        { code: 'N', codeName: '일반' },
      ],
      checkMethodItems: [],
      isSave: false,
      isSave2: false,
      isDelete: false,
      updateMode: false,
      updateMode2: false,
      saveType: 'POST',
      removeMode: false,
      key: {
        equipmentTypeCd: '',
        plantCd: '',
        equipmentCheckTypeCd: null,
        checkItemNo: '',
        checkItemNm: '',
      },
      rowTypeName: '',
      rowCheckItemName: '',
      tempInsGridDetail: {
        equipmentTypeCd:  '',
        plantCd:  '',
        equipmentCheckTypeCd:  '',
        checkItemNo:  '',
        checkItemNm:  '',
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
      },
    };
  },
  watch: {

  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.mdm.equipment.class.list.url;
      this.itemDetailUrl = selectConfig.mdm.equipment.class.insItem.list.url;
      this.insertUrl = transactionConfig.mdm.equipment.class.insItem.insert.url;
      this.deleteUrl = transactionConfig.mdm.equipment.class.insItem.delete.url;

      this.itemInfoDetailUrl = selectConfig.mdm.equipment.class.infoItem.list.url
      this.infoInsertUrl = transactionConfig.mdm.equipment.class.infoItem.insert.url;
      this.infoUpdateUrl = transactionConfig.mdm.equipment.class.infoItem.update.url;
      this.infoDeleteUrl = transactionConfig.mdm.equipment.class.infoItem.delete.url;
      this.getEquipClassList();

      this.$comm.getComboItems('MDM_METHOD_CHECK_CD').then(_result => {
        this.checkMethodItems = _result

        this.insItemgridDetail.columns = [
          {
            name: 'checkMethodCd',
            field: 'checkMethodCd',
            required: true,
            label: '검사방법',
            align: 'center',
            style: 'width:50px',
            type: 'select',
            comboItems: this.checkMethodItems,
            sortable: false,
          },
          {
            name: 'decisionBasis',
            field: 'decisionBasis',
            label: '판정기준',
            align: 'left',
            type: 'text',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'checkCaution',
            field: 'checkCaution',
            label: '점검시 안전사항',
            align: 'left',
            type: 'text',
            style: 'width:200px',
            sortable: false,
          },
        ]
      });
    },
    getEquipClassList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridClass.data = _result.data;
        this.updateMode = false;
        this.updateMode2 = false;
      },);
    },
    rowClick(row) {
      if (row) {
        this.rowTypeName = this.$_.clone(row.equipmentTypeNm);
        this.key.equipmentTypeCd = this.$_.clone(row.equipmentTypeCd);
        this.key.plantCd = this.$_.clone(row.plantCd);
      }
      this.insItemgridDetail.data = [];
      this.updateMode = true;
      this.updateMode2 = false;
      // 설비유형별 점검항목 조회
      this.$http.url = this.$format(this.itemDetailUrl, this.key.equipmentTypeCd, this.key.plantCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.insItemgrid.data = this.$_.clone(_result.data);
      },
      () => {
      });
    },
    rowClassClick(row) {
      if (row) {
        this.rowCheckItemName = row.checkItemNm;
        this.key.equipmentCheckTypeCd = this.$_.clone(row.equipmentCheckTypeCd);
        this.key.checkItemNo = this.$_.clone(row.checkItemNo);
        this.key.checkItemNm = this.$_.clone(row.checkItemNm);
      }
      this.updateMode2 = true;
      this.$http.url = this.itemInfoDetailUrl;
      this.$http.type = 'GET';
      this.$http.param = this.key;
      this.$http.request((_result) => {
        this.insItemgridDetail.data = this.$_.clone(_result.data)
      },
      () => {
      });
    },
    addrow() {
      if (!this.key.equipmentTypeCd) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '설비유형을 선택하세요.', // 설비유형을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      // 초기에는 userId에 tempId를 부여
      this.insItemgrid.data.splice(0, 0, {
        equipmentTypeCd: this.key.equipmentTypeCd,
        plantCd: this.key.plantCd,
        equipmentCheckTypeCd: null,
        checkItemNo: uuidv4(),
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        checkItemNm: '',
        click: '클릭',
        editFlag: 'C',
      })
      this.tempInsGridDetail = this.insItemgrid.data[0];
    },
    addrow2() {
      if (!this.key.checkItemNo) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '저장된 점검항목을 선택하세요.', // 저장된 점검항목을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      // 초기에는 userId에 tempId를 부여
      this.insItemgridDetail.data.splice(0, 0, {
        equipmentTypeCd: this.key.equipmentTypeCd,
        plantCd: this.key.plantCd,
        equipmentCheckTypeCd: this.key.equipmentCheckTypeCd,
        checkItemNo: this.key.checkItemNo,
        checkItemNm: this.key.checkItemNm,
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    remove() {
      let selectData = this.$refs['itemTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.insItemgrid.data = this.$_.reject(this.insItemgrid.data, { checkItemNo: item.checkItemNo })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.rowClick(null);
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    remove2() {
      let selectData = this.$refs['itemTable2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.infoDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
            data: Object.values(selectData)
          }
            this.$http.request((_result) => {
              this.$_.forEach(selectData, item => {
                this.insItemgridDetail.data = this.$_.reject(this.insItemgridDetail.data, { checkMethodCd: item.checkMethodCd })
              })
              this.deleteCallback(_result)
            },
            () => {
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveClass() {
      if (this.insItemgrid.data.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '세부항목을 하나 이상 입력하세요.',
          type: 'warning', // success / info / warning / error
        });
        return;
      }

      let flag = true;
      this.$_.forEach(this.insItemgrid.data, item => {
        if (!item.equipmentCheckTypeCd) {
            window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '설비점검종류를 선택하세요.',
            type: 'warning', // success / info / warning / error
          });
          flag = false;
        }
      })
      if (flag) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.rowClick({ plantCd: result.data.plantCd, equipmentTypeCd: result.data.equipmentTypeCd, equipmentTypeNm: this.rowTypeName })
    },
    saveClass2() {
      if (this.insItemgridDetail.data.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '세부항목을 하나 이상 입력하세요.',
          type: 'warning', // success / info / warning / error
        });
        return;
      }

      let flag = true;
      this.$_.forEach(this.insItemgridDetail.data, item => {
        if (!item.checkMethodCd) {
            window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '점검방법을 선택하세요.',
            type: 'warning', // success / info / warning / error
          });
          flag = false;
        }
      })
      // this.$_.forEach(this.insItemgridDetail.data, item => {
      //   if (item.editFlag === 'C') {
      //       this.$_.forEach(this.$_.filter(this.insItemgridDetail.data, { editFlag: null }), result => {
      //         if (item.checkMethodCd === result.checkMethodCd) {
      //           window.getApp.$emit('ALERT', {
      //             title: '안내', // 안내
      //             message: '이미 존재하는 점검방법입니다.',
      //             type: 'warning', // success / info / warning / error
      //           });
      //           flag = false;
      //         }
      //       })
      //     }
      // });
      if (flag) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.insItemgridDetail.data, item => {
              item.equipmentCheckTypeCd = this.key.equipmentCheckTypeCd;
              item.checkItemNo = this.key.checkItemNo;
              item.checkItemNm = this.key.checkItemNm;
            })
            this.isSave2 = !this.isSave2;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback2() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.rowClassClick(null)
    },
    
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.rowClassClick(null)
    },
  }
};
</script>
